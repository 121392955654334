.comment_main_div{
   display:flex;
   gap:20px;
   padding:10px;
}
.recursion_div{
    margin-left: 50px;
    margin-top:20px;
}

.user_image_individual{
    width: 80px;
    height: 80px;
    cursor: pointer;
}
.user_name{
    cursor: pointer;
}
.user_name:hover{
    color:#353F66;
}

.reply_btn_div{
    color:#353F66;
    font-size: 16px;
    cursor: pointer;
}
.text_area_reply{
    height: 100px;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
}
.comment_btn_cancel,.comment_btn_comment{
    color: white;
    background-color: rgb(188, 25, 25);
    padding: 10px 20px;
    border: none;
    margin: 0px 10px;
    border-radius: 5px;
}
.comment_btn_comment{
    background-color: rgb(18, 18, 188);
}
.paragraph_description{
    word-break: break-word;
}





/* comment Section */

@media screen and (max-width: 770px) {
    .recursion_div{
        margin-left: 20px;
        margin-top:20px;
    }
}

@media screen and (max-width: 470px) {
    .user_image_individual{
        width: 50px;
        height: 50px;
    }
    .text_area_reply{
        height: 80px;
        
    }
    .recursion_div{
        margin-left: 10px;
        margin-top:10px;
    }
    .comment_btn_cancel,.comment_btn_comment{
     
        padding: 5px 8px;
        margin-left: 5px;
        margin-top: 10px;
     
    }
}