/* .footer_part{
    width: 100%;
    position: absolute;
    bottom: 0;
} */

.footer_container {
  width: 100%;
  /* min-height: 200px; */
  background-color: #484848;
}
.inner_footer_box {
  margin: auto;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 40px 0px;
  color: white;
}
.play_store {
  display: flex;
  gap: 20px;
}

.privacy_policy_div_footer {
  width: 200px;
  text-align: right;
}
.image_box_social_media_section_footer {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  margin-top: 20px;
}
.image_box_social_media_footer {
  display: flex;

  gap: 20px;
  /* border:1px solid rgb(189, 17, 46); */
}
.link_tag {
  color: white;
}
/* bottom footer */
.bottom_footer {
  width: 100%;
  /* height: 50px; */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #dbdbdb;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  box-sizing: border-box;
  padding: 10px;
}
.bottom_footer p {
  margin-bottom: 0px;
}
.footer_link:hover {
  text-decoration: none;
  /* border:1px solid red */
}

.privacy_policy_div_footer > a {
  text-decoration: none;
}
@media screen and (max-width: 950px) {
  .inner_footer_box {
    flex-direction: column;
    margin: auto;
    text-align: center;
  }
  .left_div_top_footer {
    /* border: 1px solid red; */
    margin: auto;
    /* width: 100%; */
  }
  .play_store {
    display: flex;
    justify-content: center;
    /* width: 260px; */
    /* margin: auto; */
    margin-bottom: 20px;
  }
  .privacy_policy_div_footer {
    /* margin: 30px 0px; */
    text-align: center;
    margin: auto auto 20px;
  }

  .image_box_social_media_section {
    margin-bottom: 20px;
  }
  .image_box_social_media_section_footer {
    justify-content: center;
  }
}

@media screen and (max-width: 350px) {
  .inner_footer_box {
    width: 90%;
  }
  .play_store {
    /* flex-direction: column;
        margin: auto; */
    /* gap: 20px;
        margin-top: 30px; */
  }
  /* .play_store img{
        
        width: 70%;
    } */
}
