.card_box {
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden;
  /* transition: transform 0.3s ease-in-out; */
  cursor: pointer;
  /* max-width:  290px;  */
  padding-bottom: 20px;
  background-color: white;

  /* 
height: 375px; */
}
.product_a_tag {
  text-decoration: none !important;
  color: #000000;
}
.card_box:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.card-image {
  width: 100%;
  /* min-height: 240px; */
  /* max-height: 250px;  */
  object-fit: scale-down;
  padding: 20px;
  display: inline-block;
  aspect-ratio: 2 / 2;
}

.card-details {
  padding: 0px 16px 0px 16px;
}

.card-title {
  height: 40px;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  max-height: 3em;
  /* border: 1px solid red; */
}
.card-title > p {
  color: #1e287d;
}

.card-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* Show only 2 lines of text */
  min-height: 2.5em;
  /* Adjust height for three lines of text */

  color: #000;
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px 0px 20px;
}

.card-price {
  max-width: 267px;
  height: 36px;
  margin: auto;
  color: #ccc;
  border-radius: 15px;
  border: 1px solid #fff;
  background: #353f66;
  box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.09),
    0px 1px 4px 0px rgba(0, 0, 0, 0.19);
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 20px;
  position: relative;
}
.actual_price {
  color: #fff;
  font-family: Karla;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.off_percent {
  position: absolute;
  left: 0;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  background-color: black;
  padding: 20px;
}
@media screen and (max-width: 752px) {
  .off_percent {
    width: 50px;
    height: 50px;
    font-size: 16px;
  }
}
@media screen and (max-width: 650px) {
  .off_percent {
    width: 50px;
    height: 50px;
    font-size: 16px;
  }
}
@media screen and (max-width: 500px) {
  .card_box {
    max-width: 287px;
  }
}

@media screen and (max-width: 440px) {
  .card-details {
    padding: 0px 5px;
  }
  .card-price {
    height: 30px;
    padding-right:10px ;
  }

  /* .actual_price {
    font-size: 12px;
    font-weight: 400;
} */

.off_percent {
  width: 30px;
  height: 30px;
  font-size: 12px;
}

.card-description {
  margin: 0px 0px 10px;
}
/* .card-image {
  min-height: 158px;

} */
}

@media screen and (max-width: 350px) {
.price_hidden{
  visibility: hidden;
}

.card-price {
  justify-content: center;
 
}
}
