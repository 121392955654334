.profile_container {
  width: 100%;
  display: flex;
  gap: 20px;
  min-height: 56vh;
  margin-bottom: 50px;
}

.user_section {
  width: 100%;
  /* height: 210px; */
  /* border: 1px solid #ccc; */
  display: flex;
  gap: 20px;
  /* align-items: center; */
}
.name_type{
  
  display: flex;
  flex-wrap: wrap;
  /* gap: 20px; */
  /* align-items: center; */
  justify-content: space-around;
}
.user_name_profile{
  font-size: 20px;
  text-align: center;
  width: 80%;
  word-break: break-all;
}
.user_info_left_part{
width: 60%;
/* height: 100%; */
display: flex;
justify-content: space-between;
background-color: #FBFBFB;
border: 1px solid #F3F3F3;
padding: 10px;
}
.user_info_right_part{
  border: 1px solid #F3F3F3;
  width: 38%;
  height: 100%;
  padding: 10px;
}
/* .name_type{} */
.user_image {
  width: 150px;
  height: 150px;
  margin: 15px;
}

.profile_role {
  text-align: center;
  background-color: rgb(215, 219, 219);
  padding:3px 20px;
  border-radius: 5px;
}

.user_role {
  width: 50%;
  display: flex;
  justify-content: space-between;
}
.edit_profile_btn{
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit_btn {
  width: 150px;
  height: 40px;
  border: none;
  color: white;
  background-color: rgba(53, 63, 102, 1);
  border-radius: 20px;
}
.edit_btn:hover {
  
  background-color: rgb(68, 83, 145);
  
}
.subscription_section {
  border: 1px solid #F3F3F3;
  margin-top: 45px;
  padding: 20px;
}

.subscription_title {
  margin-bottom: 15px;
}

.user_details_box {
  /* / border: 1px solid rgb(147, 144, 144); / */
  /* margin-top: 45px; */
  /* margin-bottom: 20px; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* align-items: center; */
  justify-content: center;
  gap: 20px;
  padding: 10px;
}

.box_detail {
  /* padding: 15px; */
  /* border: 1px solid #ccc; */
  /* margin: 10px; */
  text-align: center;
  /* height: 135px; */
}
/* 
.box_detail:hover {
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 15px 15px 0px rgba(14, 14, 14, 0.05);
} */

.post_detail {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  /* width: 40px; */
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  color: #fff;
  margin-bottom: 0px;
  background: #0880C8;
  padding: 10px 0px;
}



.button_cancel{
padding: 5px 10px;
background-color: rgb(188, 25, 25);
border: none;
border-radius: 5px;
color: white;
float: right;

}

.button_cancel:hover{


  background-color: rgb(131, 6, 6);
 
  
  }
  .status_div{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 15px;
  }

.active_sub, .in_active_sub{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  
}
.active_sub{
  background-color: green;
}
.in_active_sub{
  background-color: red;
}

.user_profil_grid{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 20px;
  margin:20px auto;
}


.border_class{
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  margin-bottom: 10px;
}
.bold_title{
  font-size: 18px;
  font-weight: 700;
}

.carousel_card_div{
  /* border: 1px solid red; */
  /* max-width: 400px; */
  margin: auto;
}

.image_section{
  /* width: 150px !important; */
  /* border: 1px solid green; */
  margin: auto;
  display: flex !important;
  align-items: center !important;
  justify-content:center !important;
  padding: 0px;
}

@media screen and (max-width: 1050px) {
  .profile_container {
    display: block;
    margin-bottom: 20px;
  }
  .user_details_box {
    gap: 20px;
  }
  /* .user_role {
    width: 60%;
  } */
  .name_type{
    flex-direction: column;
    gap: 0px;
  }
}

@media screen and (max-width: 768px) {
  .user_details_box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .featured_store {
    margin: 20px auto;
  }
  .user_section {
    flex-direction: column;
    height: auto;
    
  }
.user_info_left_part, .user_info_right_part{
  width: 100%;
}
  .user_profil_grid{
   
    grid-template-columns: repeat(2,1fr);
    
  }
}

@media screen and (max-width: 500px) {
  /* .carousel_card_div{
    /* border: 1px solid red; 
    width: 400px;
    
  } */
}
@media screen and (max-width: 430px) {
  .user_image {
    width: 85px;
    height: 85px;
  }
  .user_role {
    display: block;
  }
  .user_details_box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .edit_btn {
    width: 108px;
    height: 35px;
    font-size: 14px;
  }
  /* .user_profil_grid{
   
    grid-template-columns: repeat(1,1fr);
   
    
  } */
  .button_cancel{
    float:inherit;
   margin-left: 20px;
    }
    

    /* .carousel_card_div{
      /* border: 1px solid red; 
      width: 280px;
      
    } */
}


@media screen and (max-width: 340px) {
 
    

    /* .carousel_card_div{
      /* border: 1px solid red; 
      width: 250px;
      
    } */
}