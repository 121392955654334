.offer_badge {
  border-radius: 10px 0px 10px 0px;
  position: absolute;
}

.product_name {
  color: black;
  font-size: 25px;
  font-family: Lato;
  font-weight: 300;
  margin-bottom: 0px;
}

.product_price {
  color: rgba(53, 63, 102, 1);
  font-size: 30px;
  font-family: Lato;
  font-weight: 700;
}

.deal_btn {
  width: 150px;
  height: 40px;
  border: none;
  /* / / border-radius: 20px; / / */
  color: white;
  background-color: rgba(53, 63, 102, 1);
  margin: 10px auto;
}

.social_media_div_grab_deal {
  margin-bottom: 60px;
}

.social_list_product {
  height: 30px;
  margin: 7px;
}

.comment_btn {
  color: white;
  background-color: rgba(53, 63, 102, 1);
  width: 150px;
  height: 40px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
}

/* .clear_list_single_product_page {
  align-items: flex-start;
} */

.btn_post {
  display: flex;
  align-items: center;
  justify-content: end;
}

.post_topic_btn {
  color: white;
  background-color: rgba(53, 63, 102, 1);
  width: 150px;
  height: 40px;
  border: none;
  margin: 30px 0;
}

/* .unlike{
  /* cursor: not-allowed; 
  background-color: aqua;
} */

.like {
  width: 30px;
  cursor: pointer;
  /* Add other styles as needed */
}
.like.liked {
  /* Adjust background color or any other styles to represent the liked state */
  /* background-color: blue; */
  /* Add other styles as needed */
}



/* comment section */
/* .new-comment{
  border: 1px solid red;
} */

.comment-section {
  /* display: flex;
  flex-direction: column; */
  /* align-items: center; */
  /* gap: 50px; */
  /* margin-top: 20px; */
  /* border: 1px solid red; */
  width: 100%;
  /* position: relative; */
  height: auto;
  box-sizing: border-box;
}

.comments-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.comment_div_section{
  margin-bottom:30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 10px 0px 10px 10px;
  border-radius: 10px;
}

.comment_box{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 20px;
  border-radius: 10px;
  margin-top: 30px;
}




/* .new-comment {
  
  width: 300px;
  align-self: flex-end;
} */


.text_area{
  width:100%;
  /* height: 200px; */
  border-radius: 10px;
  padding: 20px;
  border-color: rgba(53, 63, 102, 1);
}

/* .comment_btn{
  color: white;
  background-color: rgb(22, 22, 22);
  width: 150px;
  height: 40px;
  border: none;
  /* margin: 30px 0; 
  border-radius: 5px;
} */

.comment_btn:hover{
  background-color: rgb(40, 39, 39);
}


textarea:focus {
  outline: none!important;
  /* -webkit-box-shadow: none; */
  box-shadow: none;
}


.product_details_not_found{
  margin: 73px 0px;
}






@media screen and (max-width: 1200px) {
  .editor_comment {
    overflow-x: scroll;
  }
}
@media screen and (max-width: 991px) {

  .offer_badge {
    top: 0;
    left: 0;
  }
}
@media screen and (max-width: 767px) {
  .container>h2{
    font-size: 22px;
  }
  .breadcrumb{
    font-size: 14px !important;
  }
  
  .product_name {
    font-size: 20px;
  }
  .list_img {
    width: 250px;
    padding: 0px;
  }
  /* .listing_img {
    padding: 0px;
  } */
  .like {
    width: 25px;
}

.about_deal {
  font-size: 24px;
  margin-top: 20px;
}
.paragraph_description {
  padding: 0px;
}
.comment_text {
  font-size: 26px;
}

  .offer_badge {
    top: 0;
    left: 0;
  }
  /* .comment-section{
    margin-top: 10px;
  } */
}

@media screen and (max-width: 400px) {
  .product_name,
  .product_price {
    font-size: 18px;
  }
  .product_price {
    font-size: 18px;
  }
  .list_img {
    width: 200px;
  }
  /* .offer_badge {
      top: 20px;
      left: 0;
    } */
  .list_like {
    font-size: 18px;
  }
  .social_list_product {
    width: 25px;
    height: 25px;
  }
}


