.clear_list {
    width: 100%;
    display: flex;
    align-items: center;
    border: 2px solid #eeeeee;
    margin-top: 45px;
    position: relative;
    justify-content: center;
  }
  
  .desc_p {
    margin: 0; 
    padding: 10px; 
    word-wrap: break-word;
}

.list_img {
  width: 50%;
  height: auto;
  /*  border: 1px solid red;  */
  padding: 20px 0px 20px 10px;
}
  
  .listing_img {
    /* width: 60%; */
    /* width: auto; */
    max-height: 300px;
    height: auto;
    /* max-height: 250px; */
    object-fit: contain;
    padding: 20px;
    display: inline-block;
  }
  
  .list_text {
    margin: auto;
    text-align: center;
  }
  
  .list {
    color: black;
    font-size: 30px;
    font-family: Lato;
    font-weight: 300;
    margin-bottom: 0px;
  }
  
  /* .verify {
    color: #353f66;
    font-size: 45px;
    font-family: Lato;
    font-weight: 700;
  } */
  
  .social_list {
    height: 40px;
    margin: 7px;
  }
  .social_media_icon{
    margin-bottom: 50px;
  }
  .list_like {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    gap: 20px;
    /* margin-top: 15px; */
  }
  
  .about_deal {
    color: black;
    font-size: 32px;
    font-family: Lato;
    font-weight: 700;
    margin-top: 50px;
    
  }
  .paragraph_description{
    word-wrap: break-word;
    padding: 10px;
  }
  /* .list_sure {
    color: black;
    font-size: 20px;
    font-family: Lato;
    font-weight: 400;
  } */
  
  .comment_section {
    background: #fbfbfb;
    margin-bottom: 50px;
  }
  
  .comment_text {
    color: black;
    font-size: 32px;
    font-family: Lato;
    font-weight: 700;
  }
  
  /* .comment_box {
    background: white;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    width: 100%;
    margin: auto;
    position: relative;
    padding: 20px;
  } */
  
  .user_img {
    display: flex;
    gap: 15px;
  }
  
  .time {
    color: black;
    font-size: 15px;
    font-family: Lato;
    font-weight: 400;
  }
  
  .comment {
    margin-top: 50px;
    color: black;
    font-size: 20px;
    font-family: Lato;
    font-weight: 400;
  }
  
  .reply_section {
    position: absolute;
    right: 45px;
  }
  
  .reply {
    color: #353f66;
    font-size: 25px;
    font-family: Lato;
    font-weight: 700;
  }
  
  @media screen and (max-width: 991px) {
    .list
    {
      font-size: 27px;
    }
    .social_list {
      height: 30px;
    }
    .list_text {
      margin-top: 40px;
    }
    .listing_img {
      width: 100%;
      /* height: auto; */
    }
  }
  
  @media screen and (max-width: 767px) {
    .list{
      font-size: 20px;
      padding: 0px 10px;
    }
    .list_text {
      margin-top: 17px;
    }
    /* .user_img{
      display: block;
    } */
    .social_media_div_grab_deal img{
        width: 25px;
        height: 25px;
    }
    
  }

   
  @media screen and (max-width: 400px) {
    .clear_list{
      flex-direction: column;
      margin-top: 0px;
    }
    .list{
      margin-top: 0px;
    }
  }