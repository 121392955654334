/* .navbar_notificaton_div{
  margin: auto;
  text-align: center;
  padding: 10px;
  display: none;
} */
.navbar_notificaton_div {
  /* max-width: 400px; */
  color: #000;
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: none;
  padding: 2px;
}

.justify-content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.company_logo {
  width: 80px;
  height: 80px;
}
.me-autod {
  color: #353f66;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  /* font-weight: 700; */
  line-height: normal;
  text-decoration: none;
  margin-right: 5px;
  padding: 10px;
  text-align: center;
}


.active_nav {
  background-color: #353f66;
  border-radius: 5px;
  color: #fff;
}

/* .navbar a.active {
  background-color: #4caf50;
} */
.me-autod:hover {
  background-color: #353f66;
  border-radius: 5px;
  color: #fff !important;
  padding: 10px;
}
.bg-body-tertiary {
  border: 1px solid var(--Border-light, #eee);
  /* background: #FFF; */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
}
/* login and signup container */
.login_signup_box {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.button_login {
  width: 125px;
  height: 40px;
  border: none;
  border-radius: 20px;
  color: white;
  background-color: rgba(53, 63, 102, 1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.button_login:hover {
  background-color: rgb(68, 83, 145);
}
.button_register {
  width: 125px;
  height: 40px;
  border: none;
  border-radius: 20px;
  color: white;
  background-color: rgba(35, 35, 35, 1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.button_register:hover {
  background-color: rgb(85, 84, 84);
}

@media screen and (max-width: 1199px) {
  .me-autod {
    font-size: 16px;
    margin-right: 0px;
  }
}

@media screen and (max-width: 991px) {
  .company_logo {
    width: 70px;
    height: 70px;
  }
  .options {
    gap: 0px !important;
  }
  .login_signup_box {
    margin: 20px;
  }
  .navbar_notificaton_div {
    display: block;
  }

  .me-autod {
    margin-bottom: 4px;
  }
}

@media screen and (max-width: 340px) {
  .container {
  }
}
