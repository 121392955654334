.forgetpswd_main {
    /* background-color: lightgray; */
    width: 100%;
    /* height: 40vh; */
    /* border: 1px solid red; */

    border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray;
  }
  
  .forgotpswd_heading {
    font-weight: 700;
    color: #616060;
    /* border-bottom: 1px solid #ccc; */
    text-align: center;
    
    font-size: 30px;
    /* margin-left: 1%; */
    /* width: 98%; */
    margin: 130px auto 10px;
  }
  
  .forgotpswd_label {
    text-align: center;
    padding: 10px 0 0;
    font-size: 15px;
    width: 100%;
    margin-top: 0;
    font-family: Montserrat, Arial, sans-serif;
    color: #333;
  }
  
  .forgotpswd_div {
   
    /* height: 51vh; */
    border: 1px solid #EEE;
    margin-bottom: 280px;
    padding: 20px;
    text-align: center;
    
  }
  
  .forgotpswd_input {
    border: 1px solid #ddd;
    height: 55px;
    width: 100%;
    float: left;
    border-radius: 3px;
    padding-left: 10px;
  }
  
  .reset_btn_div {
    width: 210px;
    margin: auto;
  }
  
  .reset_button {
    background-color: #ffbc00;
    color: #000;
    border-radius: 5px;
    border: none;
    width: 200px;
    margin-top: 20px;
    padding: 15px;
  }

  h4{
    text-align: center;
  }