.educator_div_section {
    width: 100%; 
    display: flex; 
    justify-content: space-between;
}

.educator_and_search_section {
  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.educator-search-container {
  position: relative;
  max-width: 300px;
}

.educator-search-input {
  padding-right: 30px;
  width: 250px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 50px;
  border: none;
  background: #f5f5f5;
  padding-left: 20px;
}

.educator-search-symbol {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.educator-search-input:placeholder-shown + .educator-search-symbol {
  opacity: 1;
}

.educator_text {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}
.educator_text1{
  /* display: flex; */
  /* text-align: center; */
  /* vertical-align: middle; */
}
tr{
  text-align: center;
  vertical-align: middle;
}
.educator_list {
  display: flex;
  align-items: center;
  gap: 20px;
}
.educator_list>img{
  object-fit: contain;
  width: 150px;
  height: 150px;
}

.post_btn {
  width: 150px;
  height: 40px;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: rgba(53, 63, 102, 1);
  float: right;
 
}
.educator_no_data_found{
  min-height: 40vh;
}
.educator_pagination {
  margin-top: 65px;
  margin-bottom: 160px;
}

/* .new_topic_section{
    margin: 20px 0px;
} */
.para_title{

cursor: pointer;

}

.para_title:hover{
    color:  rgba(46, 16, 197, 1);
}
.educator_section{
  min-height: 540px;
}


@media screen and (max-width: 967px) {
  .educator_section {
      min-height: auto;
  }
  .featured_store {
    margin: 20px auto;
}
}

@media screen and (max-width:767px){
  
  .educator_text {
    flex-direction: column;
    
  }
  .educator_list{
      gap: 10px;
  }
  .educator_list>img{
    object-fit: contain;
    max-width: 130px;
    height: auto;
    height: 100px;
  }
}
@media screen and (max-width:500px){
    /* .educator_div_section {
       flex-direction: column;
    } */
    .educator_text {
   gap: 0px;
    
  }

    .educator_list{
      flex-direction: column;
  }
    .educator_and_search_section {
        margin: auto;
    }
}