.card_box1 {
    display: flex;
    flex-direction: column;
    border: 1px solid #eee;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    max-width: 287px; 
    padding-bottom: 20px;
    background-color: white;
  }
  
  .card-image1 {
    width: 100%;
    height: auto;
    max-height: 250px; 
    padding: 20px;
  }
  
  .card-details1 {
    padding: 16px;
  }
  
  .card-title1 {
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    max-height: 3em;
  }
  
  .card-title1 > p {
    color: #1e287d;
  }
  
  .card-description1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; 
    max-height: 5em; 
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 20px 0px;
  }
  
  .store1 {
    display: flex;
    width: 100%;
    gap: 20px;
  }
  
  .product_grid1 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
  }
  
  .grid1-3 {
    grid-template-columns: repeat(4, 1fr);
  }
  
  .card-image1 {
    max-width: 100%;
    height: auto;
  }
  
  .toggle-btn {
    background: transparent;
    border: none;
    padding-bottom: 15px;
  }
  .price-filter-page, .discount-filter-page{
    margin-bottom:20px;
  }
  .store_no_data_found{
    min-height: 54vh;
  }
  /* .toggle-canvas {
    display: none;
  } */
  
  /* @media screen and (max-width:1025px) {
    .hide_canvas {
      display: none;
      
    }
  } */
  
  @media screen and (max-width: 1100px) {
    .product_grid1 {
      grid-template-columns: repeat(3, 1fr);
    }
    .grid1-3 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media screen and (max-width: 767px) {
    .product_grid1 {
      grid-template-columns: repeat(2, 1fr);
    }
    .grid1-3 {
      grid-template-columns: repeat(2, 1fr);
    }
    .toggle-aside {
      display: none;
    }
    .aside_main {
      display: none;
    }
    .toggle-canvas {
      display: block;
    }
    .store_no_data_found {
      min-height: 17vh;
  }
  }
  
  
  @media screen and (min-width: 767px) and (max-width: 1024px) {
    .product_grid1 {
      grid-template-columns: repeat(2, 1fr);
    }
    .grid1-3 {
      grid-template-columns: repeat(2, 1fr);
    }
    .toggle-aside {
      display: none;
    }
    .aside_main {
      display: none;
    }
    .toggle-canvas {
      display: block;
    }
  }
 
  
  @media (max-width: 500px) {
    .product_grid1 {
      grid-template-columns: repeat(1, 1fr);
    }
    .grid1-3 {
      grid-template-columns: repeat(1, 1fr);
    }
     .card_box {
      width: 100%;
      margin: auto;
    } 
  }