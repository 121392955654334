.carousel-container {
  width: 200px;
  margin: auto;
  /* border: 1px solid #eee; */
}

.carousel_card1 {
  border: 1px solid #eee;
  border-radius: 8px;
  /* cursor: pointer; */
  background-color: white;
  padding: 20px;
  height: 202px;
  width: 250px;
  margin-top: 0;
}

.carousel_card {
  border: 1px solid #eee;
  border-radius: 8px;
  /* cursor: pointer; */
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
}

.featured-carousel .carousel-item img {
  border-radius: 5px;
  object-fit: cover;
}

.feature_img {
  width: 200px;
  height: 100px;
  cursor: pointer;
  
}
.image_store1{
  width: 150px;
}
@media screen and (max-width: 1240px) {
  .carousel_card {
    display: block;
    margin-bottom: 20px;
  }
  .carousel_card1 {
    margin: auto;
  }
}

@media screen and (max-width: 990px) {
  .carousel_card {
    display: block;
    margin-top: 10px;
  }
}

/* @media screen and (max-width: 565px) {
  .carousel_card {
    /* width: 295px; 
    display: flex;
    align-items: center;
    justify-content: center;
  }
} */