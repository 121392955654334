::-webkit-scrollbar {
  display: none;
}

.image_store{
  width: 140px;
}

.canvas {
  /* width: 80%; */
  overflow-y: hidden;
  height: auto;
}
