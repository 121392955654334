.top_section{
    height: 40px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
   
    
}
/* .top_container{
    width:69%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
   
} */
.navbar_notificaton{
    /* max-width: 400px; */
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  
}
.image_box{
    display: flex;
    gap:20px;
    align-items: center;
}
.social_media_image{
    width: auto;
    height: auto;
}

.image_box>span{
    color: #000;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.image_box_social_media_section_topNav{
    /* border: 1px solid red; */
    box-sizing: border-box;
    display: flex;
    gap:20px;
    box-sizing: border-box;
    align-items: center;
    width: 95px;
    /* display: flex;
    gap:20px */
}

.search-box {
    position: fixed;
    top: 0;
    right: -100%; 
    width: 100%;
    height: 60px;
    background-color: white;
    z-index: 999;
    /* transition: right 0.5s ease-in-out; */
    background-color: rgb(53, 63, 102);
    transition: width 2s;
   
  }
  
  .search-box.show {
    height: 40px;
    right: 0; 
    transition-timing-function: ease-in-out;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: space-between;
  }

  .search-content {
    /* padding: 20px; */
    /* position: relative; */
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: space-between;
  }
  
  .close-button {
    
    cursor: pointer;
    background: none;
    border: none;
    font-size: 20px;
    color: rgb(245, 235, 235);
  }

  .search-container {
    position: relative;
    /* border: 1px solid red; */
    width: 100%;
    /* max-width: 400px; */
  }

  .search-input {
    padding-right: 30px;
    width: 100%;
    height: 35px;
    flex-shrink: 0;
    /* border-radius: 50px; */
    border: none;
    background: rgb(53, 63, 102);
    padding-left: 20px;
    color: #ffffff;
    
  }
  input:focus {
    outline: none!important;
    /* -webkit-box-shadow: none; */
    box-shadow: none;
  }
  
.fa-search{
  cursor: pointer;
}




@media screen and (max-width: 991px) {
  .navbar_notificaton{
      visibility: hidden;
  }
}


@media screen and (max-width: 600px) {
  /* .search-container {
   
    max-width: 300px;
  }

  .search-input {
    
    width: auto;
    
    
  } */

  .navbar_notificaton{
    display: none;
    }
}

@media screen and (max-width: 400px) {
  .image_box{
    gap:10px;
    
}
  .social_media_image{
    width:80px;
    height:25px;
}
}


  