.image-container {
  position: relative;
  max-width: 420px;
  /* background-color: white; */
  border-radius: 5px;
  /* margin-right: 20px; */
  cursor: pointer;
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
}

.text-overlay {
  position: absolute;
  top: 28%;
  left: 22%;
  /* transform: translate(-50%, -50%); */
}
.text-overlay p {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  margin-bottom: 4px;
  color: white;
  font-size: 18px;
  text-align: left;
}

/* Increase arrow size */
.slick-prev:before,
.slick-next:before {
  font-size: 25px; /* Adjust the size as needed */
  color: rgba(53, 63, 102, 1);
  
  margin-right: 20px;
}

@media screen and (max-width: 450px) {
  
  .text-overlay {
    top: 25%;
    left: 18%;
    /* transform: translate(-50%, -50%); */
  }
  .text-overlay p {

    font-size: 16px;
    
  }
 
}