/* * .container {
    margin-top: 20px;
  } */ 
  
  /* .toggle-button {
      margin-bottom: 10px;
      padding: 10px;
      background-color: #3498db;
      color: #fff;
      cursor: pointer;
    } */
  
  .card_box1 {
    display: flex;
    flex-direction: column;
    border: 1px solid #eee;
    border-radius: 8px;
    overflow: hidden;
    /* / transition: transform 0.3s ease-in-out; / */
    cursor: pointer;
    max-width: 287px; 
    padding-bottom: 20px;
    background-color: white;
    /* / height: 375px; / */
  }
  
  .card-image1 {
    width: 100%;
    height: auto;
    max-height: 250px;
    /* / object-fit: cover; / */
    padding: 20px;
  }
  
  .card-details1 {
    padding: 16px;
  }
  
  .card-title1 {
    /* / height: 50px; / */
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    max-height: 3em;
    /* / border: 1px solid red; / */
  }
  
  .card-title1 > p {
    color: #1e287d;
  }
  
  .card-description1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    max-height: 5em;
    /* / Adjust height for three lines of text / */
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 20px 0px;
  }
  
  .store1 {
    display: flex;
    width: 100%;
    gap: 20px;
   
  }
  
  .product_grid1 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
    margin: 10px 0px;
  }
  
  .grid1-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  
  /* .card_box {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  } */
  
  .card-image1 {
    max-width: 100%;
    height: auto;
  }
  /* .store_no_data_found{
    min-height: 45vh;
  } */
  
  .single_page_product{
    height: 52vh;
  }
  /* / Aside.css / */
  
  /* .aside {
    display: none;
    background-color: #f1f1f1;
    padding: 10px;
  }
  
  .aside.open {
    display: block;
  } */
  
  .toggle-btn:hover{
    color: #1e287d;
  }
  @media screen and (max-width: 1200px) {
    .product_grid1 {
      grid-template-columns: repeat(4, 1fr);
    }
    
  }
  @media screen and (max-width: 1100px) {
    /* .product_grid1 {
      grid-template-columns: repeat(3, 1fr);
    } */
    .grid1-3 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (max-width: 991px) {
    .product_grid1 {
      grid-template-columns: repeat(3, 1fr);
    }
    
  }
  
  @media screen and (max-width: 767px) {
    .store1.aside-open {
      width: 100%;
    }
    .product_grid1 {
      grid-template-columns: repeat(2, 1fr);
    }
    .grid1-3 {
      grid-template-columns: repeat(1, 1fr);
    }
    .single_page_product {
      height: 17vh;
  }
  }
  /* @media screen and (max-width: 426px) {
    
    .product_grid1 {
      grid-template-columns: repeat(1, 1fr);
    }
    
  } */
  
