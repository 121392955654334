.card_box_category{
    display: flex;
    flex-direction: column;
    border: 1px solid #EEE;
    border-radius: 8px;
    overflow: hidden;
    /* transition: transform 0.3s ease-in-out; */
    /* cursor: pointer; */
    /* max-width:  290px;  */
    padding-bottom: 20px;
    background-color: white;
   
  }

.card_box_category:hover{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}


  
  .card-price_category{
        max-width: 267px;
        height: 36px;
        margin: auto;
        color: #ccc;
        border-radius: 15px;
        border: 1px solid #FFF;
        background:  #353F66;
        box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.09), 0px 1px 4px 0px rgba(0, 0, 0, 0.19);
        display: flex;
        align-items: center;
        justify-content: end;
        padding-right: 20px;
        position: relative;
        cursor: pointer;
  }
  .card-image-category,.card-title{
    cursor: pointer;
  }

  .card-image-category{
    width: 100%;
    min-height: 245px;
    max-height: 250px; 
    object-fit: contain;
    padding: 20px;
    display: inline-block;
  }
 
  
@media screen and (max-width: 440px) {
  .card-image-category {
    min-height: 200px;
    padding: 5px;
}
  .card-price_category{
    padding: 15px;
  }

.grab_deal{
  font-size: 13px;
}

}