



/* box */
.alert{
    margin-top: 10px;
}

.coupon-page-block {
    padding-bottom: 50px;
}

.submit-deal-main-block {
    padding: 25px 30px 15px;
    background-color: #FFF;
    box-shadow: 0 0 3px 0 rgba(0,0,0,0.1);
}

form {
    display: block;
    margin-top: 0em;
}
.form-group {
    margin-bottom: 15px;
}
.submit-deal-main-block label {
    margin-bottom: 10px;
    font-weight: 600;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
}
.submit-deal-form .form_control {
    border-color: rgba(0,0,0,0.1);
    border-radius: 3px;
}
.form_control {
    width: 100%;
    height: 50px;
    background-color: #FFF;
    border-radius: 0;
    border: 1px solid #FFF;
    color: #333;
    line-height: 1.69;
}

.contact-page .submit-deal-btn {
    margin-top: 30px;
}

.btn_primary {
    position: relative;
    background-color: rgb(53, 63, 102);
    color: #FFF;
    border: none;
    height: 40px;
    border-radius: 3px;
    line-height: 1.6;
    /* border: 1px solid red; */

}
.form-group .btn_primary {
    width: 100%;
    font-size: 15px;
}

.featured_carousel_small_screen{
    display: none;
}



.deal_box,.deal_box1{
    /* display: block; */
    width: 150px;
    height: 85px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 5px;
    margin: 0px 10px 10px 0px;
    color: #333;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px; 
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
}
.deal_box.active , .deal_box1.active1{
    border: 1px solid rgb(53, 63, 102);
}

.hand_shake{
    font-size: 30px;
    color: rgb(53, 63, 102);
}
.deal_box:hover{
    border-color: 1px solid rgba(0,0,0,0.1);
}
.deal_box1:hover{
    border-color: 1px solid rgba(0,0,0,0.1);
}


@media only screen and (max-width:767px){
    .featured_carousel_small_screen{
        display: block;
    }
    .featured_carousel_long_div{
        display: none;
    }
    .carousel-container {
       
        margin: auto;
    }
}
