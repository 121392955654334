.forum-page-header {
    padding-bottom: 7px;
  }
  
  
  
  .ac-profile-img {
    margin-bottom: 20px;
  }
  
  .coupon-page-block {
    padding-bottom: 50px;
  }
  
  .edit-profile-main-block {
    padding: 25px 30px 15px;
    background-color: #fff;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
  }
  
  form {
    display: block;
    margin-top: 0em;
  }
  .form-group {
    margin-bottom: 15px;
  }
  .edit-profile-main-block label {
    margin-bottom: 10px;
    font-weight: 600;
  }
  
  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
  .edit-profile-form .form_control {
    border-color: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }
  .form_control {
    width: 100%;
    height: 50px;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #fff;
    color: #333;
    line-height: 1.69;
    padding-left: 10px;
  }
  .text_area {
    height: 150px;
  }
  
  .profile-page .submit-profile-btn {
    margin-top: 30px;
  }
  
  .btn_primary {
    position: relative;
    background-color: rgb(53, 63, 102);
    color: #fff;
    border: none;
    height: 40px;
    border-radius: 3px;
    line-height: 1.6;
  }
  .btn_primary:hover{
    background-color: rgb(68, 83, 145)
  }
  
  .form-group .btn_primary {
    width: 100%;
    font-size: 15px;
  }
  
  .featured_carousel_small_screen {
    display: none;
  }
  
  .pswd_change:hover {
    color: rgba(46, 16, 197, 1);
    cursor: pointer;
  }
  
  .error_password_notification{
    color: red;
  }

  .user_image_profile{
    width: 150px;
    height: 150px;
  }
  .star_color{
    color: red;
  }
  @media only screen and (max-width: 767px) {
    .featured_carousel_small_screen {
      display: block;
    }
    .featured_carousel_long_div {
      display: none;
    }
    .carousel-container {
      margin: 20px auto;
    }
    .user_image_profile {
      width: 75px;
      height: 75px;
    }
    .text_area {
      height:75px;
      border-radius: 5px;
      padding: 0px;
  }
  }