.following_section {
    width: 100%;
    border: 1px solid #F3F3F3;
    margin: 10px 0px;
    padding: 10px 20px;
}

.follow_text { 
    color: rgb(61, 72, 118);
}


.image_following{
    /* / border: 1px solid red; / */
    /* border-radius: 50%; */
    height: 80px;
    width: 80px;
    padding: 10px;
    cursor: pointer;
}
.profile_user{
    width:80%;
}
.common_profile_no_data_found{
    min-height: 56vh;
}
@media only screen and (max-width:1399px){
    .profile_user{
        width:76%;
    }
}
@media only screen and (max-width:1050px){
    .profile_user{
        width:100%;
    }
}