.breadcrumb-item a {
  color: black;
  text-decoration: none;
}

.breadcrumb-item a:hover {
  color: rgba(46, 16, 197, 1);
}

.breadcrumb .breadcrumb-item.active {
  color: rgba(46, 16, 197, 1);
  font-weight: 700;
}

.store {
  display: flex;
  width: 100%;
  gap: 20px;
  margin-bottom: 70px;
}
.store_no_data_found{
  /* min-height: 40vh; */
  /* border: 1px solid red; */
}

/* .store_main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
} */

.productgrid {
    width: 100%; 
  /* / display: grid; /
  / grid-template-columns: repeat(4, 1fr); /
   / grid-gap: 15px; / */
   margin: auto;
}

/* .store_card {
  border: 1px solid #eee;
  border-radius: 8px;
  cursor: pointer;
  background-color: white;
  padding: 20px;
} */

/* .storeimg {
  margin: auto;
} */

/* .store_image {
  / width: 290px; /
  / height: 100px; /
  /* padding: 20px; 
  width: 290px;
  max-height: 100px;
  object-fit: cover;
  padding: 20px;
} */

.page {
  margin: auto; 
  margin-bottom: 20px;
}


@media screen and (max-width: 1240px) {
  .store {
    display: flex;
    flex-direction: column;
  }
}

/* / @media screen and (max-width: 990px) { /
  /* .store_main {
    grid-template-columns: repeat(2, 1fr);
  } */
  /* .store{
    display: flex;
    flex-direction: column; 
  } 
/ } / */

@media screen and (max-width:450px) {
  .box {
    margin: auto;
    width: 100%;
  }
}

@media screen and (max-width: 565px) {
  /* .store_main {
    grid-template-columns: repeat(1, 1fr);
  } */
  .store {
    display: flex;
    flex-direction: column;
  }
}