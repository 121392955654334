.register_text{
    color: rgba(53, 63, 102, 1);
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
}

.blank_message{
    color:red;
}
.loginAndRegisterForm{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    margin:78px auto;
    padding: 20px auto;
}

@media screen and (max-width:515px){
    .loginAndRegisterForm{
        margin:50px auto;
    }
  }