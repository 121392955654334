
.form-control:focus {
  background-color: #fff;
  background-color: var(--bs-body-bg);
  border-color: none !important; 
  box-shadow: none !important;
}
.category_and_search_section{
    max-width: 950px;
    height: 70px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid red; */
  
}
.select_container {
  max-height: 200px; /* Set maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.select_section{
    display: flex;
    gap:20px;
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.select_option_value{
  width:450px;
  height:40px;
  box-sizing:border-box;
  padding-left:20px;
    /* border: 1px solid red; */
  
}

/* .select_option_value select{
  border: 1px solid yellow;
color: aqua;
background-color: lawngreen;
height: 100px !important;
width: 90px;
} */

.select_option_value1{
height: 100px !important;

}




.banner_background{
    background-image: url("../Pictures/banner_background_image.png");
    color: #000;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px;
}
.home_min_height{
  min-height: 480px;
}
.banner_background>div{
       /* width: 69%;  */
        margin: auto;
        padding: 20px 0px;
}
.banner_background>div>p>span{
    font-weight: 700;
}

  /* trendig deals */
/* .body_product_section{
    width: 69%;
    margin: auto;
} */
  .trending_deals, .recent_deals{
    width: 100%;
    margin: auto;
  }
.trending_para, .recent_para{
  margin: 20px auto;
  /* width: 69%; */
}

.trending_para , .recent_para{
    color: #000;
    font-family: Lato;
    font-size: 20px;
}

.trending_para>span, .recent_para>span{
  font-weight: 700;
}


.product_grid, .recent_grid{
  display:grid;
  grid-template-columns:repeat(5,1fr);
  gap:20px;
  margin-bottom:30px;
}

.load_more{
  max-width: 400px;
  height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50px;
  border: 1px solid  #EEE;
  background: #383838;
  color: #FFF;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
  cursor: pointer;
}

.spinnerHome{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
  /* background-color: none; */
}

.loading_home{
min-height: 450px;
}
.home_no_data_found{
  min-height:37vh;
  /* border: 1px solid red; */
}

.page_not_found{
  min-height:62vh;
}
  /* banner section  */

  @media screen and (max-width: 1434px) {
    .category_and_search_section{
      
      flex-direction: column;
      /* gap:20px; */
      height: auto;
      padding: 10px;
      /* border: 1px solid black; */
  }
  }
  @media screen and (max-width: 991px) {

    .select_option_value{
      max-width: 340px;
    }
    /* .select_section{
      display: flex;
      flex-direction: column;
      gap:20px;
      
  } */
  
  /* .select_option_value{
   
    padding-left:20px
  } */
  }

  @media screen and (max-width:767px) {

    .select_option_value{
      max-width: 400px;
    }
    .select_section{
      display: flex;
      flex-direction: column;
      gap:20px;
      
  }
  .page_not_found{
    min-height:32vh;
  }
  
  /* .select_option_value{
   
    padding-left:20px
  } */
  }
@media screen and (max-width:500px){
  .select_option_value{
    max-width:310px;
   
  }
  .select_option_value option{
    width:100%;
  }
}

  /* product grid section */

  @media screen and (max-width: 1280px) {
    .product_grid, .recent_grid{
      grid-template-columns:repeat(4,1fr);
     
    }
    
  }
  
  @media screen and (max-width: 991px) {
    .product_grid, .recent_grid{
      /* width: 80%; */
      grid-template-columns:repeat(3,1fr);
      margin-bottom: 20px;
      margin: auto;
     
    }
    .load_more{
      margin-top: 20px;
    }
    
   
  }
  @media screen and (max-width: 767px) {
    .product_grid, .recent_grid{
      /* width: 80%; */
      grid-template-columns:repeat(2,1fr);
      margin-bottom: 20px;
      margin: auto;
     
    }
    
   
  }


  @media screen and (max-width: 500px) {
    /* .product_grid, .recent_grid{
      
      grid-template-columns:repeat(1,1fr);
     
    } */
    .category_and_search_section {
        gap: 0px;
        padding: 10px;
    }

    .select_section{
      gap: 10px;
    }
    /* .trending_para , .recent_para{
      margin-left: 15%;
  } */
    .load_more{
      max-width: 250px;
      height: 50px;
      font-size: 18px;
    }
    
  
  }



  @media screen and (max-width: 450px) {
  .product_grid, .recent_grid {
    gap: 6px;
}

  }

  @media screen and (max-width: 400px) {
    .load_more {
        max-width: 200px;
        height: 40px;
        font-size: 15px;
    }
   
}
  /* Grid for 2 box and their css */



  