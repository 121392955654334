.forum-page-header {
    padding-bottom: 7px;
}
.breadcrumb-main-block {
    /* background-color: #F4F4F4; */
}



/* box */


.coupon-page-block {
    padding-bottom: 50px;
}

.submit-deal-main-block {
    padding: 25px 30px 15px;
    background-color: #FFF;
    box-shadow: 0 0 3px 0 rgba(0,0,0,0.1);
}

form {
    display: block;
    margin-top: 0em;
}
.form-group {
    margin-bottom: 15px;
}
.submit-deal-main-block label {
    margin-bottom: 10px;
    font-weight: 600;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
}
.submit-deal-form .form_control {
    border-color: rgba(0,0,0,0.1);
    border-radius: 3px;
}
.form_control {
    width: 100%;
    /* height: 50px; */
    background-color: #FFF;
    border-radius: 0;
    border: 1px solid #FFF;
    color: #333;
    line-height: 1.69;
    padding-left: 10px;
}

.contact-page .submit-deal-btn {
    margin-top: 30px;
}

.btn_primary {
    position: relative;
    background-color: rgb(53, 63, 102);
    color: #FFF;
    border: none;
    height: 40px;
    border-radius: 3px;
    line-height: 1.6;
    /* border: 1px solid red; */

}
.form-group .btn_primary {
    width: 100%;
    font-size: 15px;
}

.featured_carousel_small_screen{
    display: none;
}

@media only screen and (max-width:767px){
    .featured_carousel_small_screen{
        display: block;
    }
    .featured_carousel_long_div{
        display: none;
    }
    .carousel-container {
       
        margin: auto;
    }
}
