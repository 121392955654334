.forum_section{
    width: 100%;
    display: flex;
    gap: 20px;
    margin: 100px auto 130px;
    /* border:1px solid red; */
  }
  .forum_section_left_div{
    width: 80%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;

  }
  .loading-spinner-forum{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
  .forum_box {
    background: #fff;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    /* width: 40%; */
    border: 1px solid #eee;
    padding: 20px;
    margin-bottom: 35px;
    /* border: 1px solid blue; */
  }
  
  .forum_btn {
    display: flex;
    gap: 20px;
    justify-content: space-between;
  }
  
  .forum_button {
    width: 47%;
    padding: 10px 0px;
    border: none;
    color: white;
    background-color: rgba(53, 63, 102, 1);
    margin-bottom: 10px;
  }
  .forum_button:hover{
    background-color: rgb(51, 69, 139);
  }

  .education_post {
    font-weight: 700;
  }

 
  @media screen and (max-width: 1241px) {
    .forum_section{
    width: 100%;
    flex-direction: column;
    margin:20px auto;
      
      }
      .forum_section_left_div{
        width: 100%;
        margin: auto;
        
    
      }
     
  }
 

  @media screen and (max-width: 991px) {
    .forum_section{
   width: 100%;
      
      }
       
      .forum_button{
        padding: 10px 10px;
      }
  }
  
  
  @media screen and (max-width: 767px) {
    .forum_section_left_div{
        width: 100%;
        grid-template-columns: repeat(2,1fr);
        margin: auto;
    
      }
      
  }
  @media screen and (max-width: 767px) {
    
    .forum_btn {
        flex-direction: column;
        gap: 0px;
       
      }
      .forum_button{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
     
  }
  
  @media screen and (max-width: 400px) {
    
      .forum_section_left_div{
        grid-template-columns: repeat(1,1fr);
        
    
      }
  }