/* button {
    padding: 8px 16px;
    margin: 0 5px;
    cursor: pointer;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
  } */

/* .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  } */

/* button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  } */

/* .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .pagination button:hover {
    background-color: rgb(89, 102, 156);
  }
  
  .pagination button.active {
    background-color: rgba(53, 63, 102, 1);
  }



  .pagination button {
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    background-color: rgba(53, 63, 102, 1);
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  .pagination button:disabled {
    background-color: #ddd;
    color: #555;
    cursor: not-allowed;
  } */

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination button,
  .pagination span {
    padding: 8px 15px;
    margin: 0 3px;
    border: 1px solid #ddd;
    cursor: pointer;
    background-color: #f8f9fa;
  }
  
  .pagination button:hover,
  .pagination span:hover {
    background-color: rgba(53, 63, 102, 1);
    color: #fff;
  }
  
  .pagination button.next_btn {
    background-color: rgba(53, 63, 102, 1);
    color: #fff;
  }
  
  .pagination .ellipsis {
    font-weight: bold;
  }
  
  .pagination .active {
    background-color: rgba(53, 63, 102, 1);
    color: #fff;
  }

  @media screen and (max-width: 791px) {
    .pagination button,
    .pagination span {
      padding: 8px 10px;
      margin: 0 2px;
    }
  }
  
  @media screen and (max-width: 500px) {
    .pagination button,
    .pagination span {
      padding: 8px;
      margin: 0 1px;
    }
  }
  @media screen and (max-width: 450px) {
    .pagination button,
    .pagination span {
      font-size: 18px;
      margin: 0 2px 2px;
      padding: 3px 8px;
    }
    .pagination{
      flex-wrap: wrap;
    }
  }