.search_no_data_found{
    height: 42vh;
}
.loading-spinner{
    min-height: 500px;
    display: flex;
    text-align: center;
    margin: 50px;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

.row_reverse{
    width: auto;
    display: flex;
    flex-direction: row-reverse
}