
.loginAndForm{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  margin: 120px auto;
}

  /* Login section */
  .login_text{
    color: rgba(53, 63, 102, 1);
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;

  }
  .input_box{
        width: 100%;
        /* border: 1px solid #e0dddd; */
        height: 45px;
        border-radius: 0px !important;
        padding-left: 20px;
  }
  .focused {
    box-shadow: none;
    border: 2px solid rgba(53, 63, 102, 1);
  }  
.forgot-password{
    text-align: end;
    cursor: pointer;
  }
  .forgot-password:hover{
    color: #FF9B00;
  }
.login_button{
    width: 100%;
    background-color: rgba(53, 63, 102, 1);
    color: #FFF;
    border: none;
    height: 40px;
    border-radius: 3px;
    line-height: 1.6;
    text-transform: uppercase;
  }
  .login_button:hover{
      background-color:#3B5998 ;
  }
  .or_para{
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    margin: 10px;
  }

  .social-login{
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .button_social_media,.button_social_media_google{
      
        color: #FFF;
        background-color: #3B5998;
        font-size: 15px;
        border: none;
        padding: 10px 8px;
        border-radius: 5px;
        box-sizing: border-box;
        text-transform: uppercase;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        gap: 10px;
        
  }
  .button_social_media>span{
    padding-left: 10px;
  }
  .button_social_media_google{
    background-color: #DB4A39;
  }
  .button_social_media_google>span{
    padding-left: 10px;
  }

  .register_now{
    text-align: center;
    margin-top: 20px;
    
  }
  .link{
    text-decoration: none;
    color: black;
    cursor: pointer;
  }
  .link:hover{
    
    color: #FF9B00;
  }
 
  .form-group .btn-primary {
    /* background-color:  rgba(53, 63, 102, 1); */
    width: 100%;
    font-size: 15px;
    border: none;
}
.btn-primary {
  background-color:  rgba(53, 63, 102, 1);
  width: 100%;
  font-size: 15px;
  border: none;
}
.btn-primary:hover {
  background-color:  #3b5998;

}
.fb-btn {
    background-color: #3b5998;
    line-height: 1.9;
    border: none;
}
.fb-btn:hover {
  background-color: #3b5998;
}
.gplus-btn {
  background-color: #db4a39;
  line-height: 1.9;
  border: none;
}
.gplus-btn:hover{
  background-color: #db4a39;
}
.svg_icon{
  margin-right: 10px;
}
.footer_modal{
  
}
.delete_account{
  min-height: 67vh;
}
.delete_confirm_yes {
  width: 30%;
}
.delete_confirm_no {
  width: 30%;
}
  @media screen and (max-width:515px){
    .social-login{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .button_social_media,.button_social_media_google{
        width: 100%;

        
  }

  .loginAndForm{
    margin: 50px auto;
  }
  }