.deal_card_box {
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden;
  /* / / / transition: transform 0.3s ease-in-out; / / / */
  cursor: pointer;
  /* / / / max-width:  290px;  / / / */
  /* / padding-bottom: 20px; / */
  background-color: white;
  text-align: center;
  /* / height: 375px; / */
}

.deal_card_box:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.deal_image {
  width: 100%;
  height: auto;
  /* max-height: 250px; */
  /* / / / object-fit: cover; / / / */
  padding: 20px;
}

.deal-details {
  padding: 16px;
}

.deal-title {
  /* / / / height: 50px; / / / */
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  max-height: 3em;
  /* / / / border: 1px solid red; / / / */
}

.deal-title > p {
  color: #1e287d;
  margin-bottom: 0px;
}

.deal-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  min-height: 38px;
  /* height: 21px; */
  /* / / / Adjust height for three lines of text / / / */
  color: #000;
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px 0px 20px;
}

.deal-info {
  max-width: 267px;
  height: 75px;
  margin: auto;
  color: #ccc;
  border-radius: 15px;
  border: 1px solid #fff;
  background: #353f66;
  box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.09),
    0px 1px 4px 0px rgba(0, 0, 0, 0.19);
  display: block;
  /* align-items: center;
  justify-content: center; */
  /* / / padding-right: 20px; / /
  / / position: relative; / / */
}

.deal_user > span {
  color: #fff;
  font-family: Karla;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.deal_image {
  position: absolute;
  left: 0;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-family: Karla;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  border: 1px solid #353f66;
  padding: 20px;
  /* / / background-color: black; / / */
}

.deal_rating_box {
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
  padding: 16px 0px;
}

.deal_user_detail {
  display: flex;
  gap: 10px;
}

.deal_user_image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  /* border: 1px solid #a19f9f; */
}

.user_detail {
 text-align: left;
}

@media screen and (max-width: 752px) {
  .deal_image {
    width: 50px;
    height: 50px;
    font-size: 16px;
  }
}
@media screen and (max-width: 650px) {
  .deal_image {
    width: 50px;
    height: 50px;
    font-size: 16px;
  }
}
@media screen and (max-width: 500px) {
  .deal_card_box {
    width: 100%;
    max-width: 287px;
    margin: auto;
  }
}
@media screen and (max-width: 450px) {
  .deal_rating_box {
    display: block;
    padding: 0px 5px;
    margin-top: 15px;
}
.deal_user_detail {
  justify-content: space-between;
}
.deal_product_detail{
  display: flex;
  padding: 0 20px 0px 0px;
  justify-content: space-between;
  /* gap: 35%; */
}
}
@media screen and (max-width: 400px) {
  .deal_user_image {
    width: 30px;
    height: 30px;
}
.user_detail {
  font-size: 14px;
}
}